import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  query,
  setDoc,
} from "firebase/firestore";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function CreateMachineProduct() {
  let params = useParams();
  let navigate = useNavigate();
  const machineId = params.machineId;
  const [data, setData] = useState({});
  const [products, setProducts] = useState({});

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setData({ ...data, [name]: value });
  };

  const onClose = () => {
    navigate(`/machines/${machineId}/products`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formatLocation = data.location.map((location) => {
      return {
        stock: parseInt(location.stock),
        tray: parseInt(location.tray),
        channel: parseInt(location.channel),
        end_date: location.end_date,
        available: location.available,
        dispense_speed: 5,
        dispense_afterrun: 3,
      };
    });

    // Create new product in the products document in firebase
    await setDoc(
      doc(
        getFirestore(),
        `machines/${machineId}/products`,
        data.product_reference
      ),
      {
        available_stock: parseInt(data.available_stock),
        price: parseFloat(data.price),
        display_order: parseInt(data.display_order),
        location: formatLocation,
        reference: doc(
          getFirestore(),
          "products/" +
            (data.product_reference
              ? data.product_reference
              : Object.keys(products[0]).id)
        ),
        ...(data.discount_price && {
          discount_price: parseFloat(data.discount_price),
        }),
        ...(data.badge && {
          badge: data.badge,
        }),
        
      }
    )
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        navigate(`/machines/${machineId}/products`);
      });
  };

  useEffect(() => {
    getDocs(query(collection(getFirestore(), "products"))).then(
      async (querySnapshot) => {
        let products = {};
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          products[doc.id] = data;
        });

        // Filter out products that are already in the machine
        await getDocs(
          query(collection(getFirestore(), `machines/${machineId}/products`))
        ).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            data.id = doc.id;
            delete products[doc.id];
          });
        });

        setProducts(products);
      }
    );
  }, [machineId]);

  const handleLocationChange = (event, locationIndex, type) => {
    let value;
    let name;

    if (type === "Date") {
      value = new Date(event.$d);
      name = "end_date";
    } else {
      const target = event.target;
      value = target.value;
      name = target.name;
    }

    const location = data.location[locationIndex];
    if (type === "Date") {
      location[name] = value;
    } else {
      if (name !== "available") {
        location[name] = parseInt(value);
      } else {
        location[name] = value;
      }
    }

    setData({
      ...data,
      location: [
        ...data.location.slice(0, locationIndex),
        location,
        ...data.location.slice(locationIndex + 1),
      ],
    });
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">
        Create a new product
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Table size="small" sx={{ maxWidth: 800 }}>
            <TableBody>
              <TableRow>
                <TableCell>Locations*</TableCell>
                <TableCell>
                  <p>Stock, tray and channel for each location</p>
                  <Button
                    onClick={() => {
                      setData({
                        ...data,
                        location: [
                          ...(data.location ? data.location : []),
                          { stock: 0, tray: 0, channel: 0 },
                        ],
                      });
                    }}
                  >
                    Add location
                  </Button>
                  {data.location && data.location.length > 0 ? (
                    data.location.map((location, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          Stock*
                          <br />
                          <TextField
                            style={{ width: "200px" }}
                            name="stock"
                            type="number"
                            required
                            onChange={(e) => {
                              handleLocationChange(e, index);
                            }}
                          />
                          <br />
                          <br />
                          Tray*
                          <br />
                          <TextField
                            style={{ width: "200px" }}
                            name="tray"
                            type="number"
                            required
                            onChange={(e) => {
                              handleLocationChange(e, index);
                            }}
                          />
                          <br />
                          <br />
                          Channel*
                          <br />
                          <TextField
                            style={{ width: "200px" }}
                            name="channel"
                            type="number"
                            required
                            onChange={(e) => {
                              handleLocationChange(e, index);
                            }}
                          />
                          <br />
                          <br />
                          Enddate*
                          <br />
                          <DatePicker
                            name="end_date"
                            onChange={(e) => {
                              handleLocationChange(e, index, "Date");
                            }}
                          />
                          <br />
                          <br />
                          Available*
                          <br />
                          <Select
                            style={{ width: "200px" }}
                            name="available"
                            required
                            onChange={(e) => {
                              handleLocationChange(e, index);
                            }}
                          >
                            <MenuItem value={true}>True</MenuItem>
                            <MenuItem value={false}>False</MenuItem>
                          </Select>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <p>No locations added</p>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Available stock*</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="available_stock"
                    type="number"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>New Badge</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="badge"
                    type="text"
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Price*</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="price"
                    type="number"
                    required
                    inputProps={{
                      step: "0.01",
                    }}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Discount price</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="discount_price"
                    type="number"
                    inputProps={{
                      step: "0.01",
                    }}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Display Order</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="display_order"
                    type="number"
                    inputProps={{
                      step: "1",
                    }}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Product reference*</TableCell>
                <TableCell>
                  {products && Object.values(products).length > 0 ? (
                    <Select
                      style={{ width: "200px" }}
                      name="product_reference"
                      required
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    >
                      <MenuItem selected disabled value="">
                        <em>None</em>
                      </MenuItem>
                      {Object.values(products).map((product) => (
                        <MenuItem key={product.id} value={product.id}>
                          {product.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <p>No products available or already in the machine</p>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Button
            style={{ marginTop: "20px" }}
            type="submit"
            variant="contained"
          >
            Create machine product
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default CreateMachineProduct;
