import {

    Button,
  
} from "@mui/material";

import { useNavigate } from "react-router-dom";

const MachineMenu = function (machine, machineId) {
    const navigate = useNavigate();
    return (
        
            <><Button
            style={{ marginBottom: "20px" }}
            onClick={() => navigate(`/machines/${machineId}/`)}
            variant="contained"
        >
            {machine ? machine.location : "machine Info"}
        </Button><Button
            style={{ marginBottom: "20px" }}
            onClick={() => navigate("products")}
            variant="contained"
        >
                Products
            </Button><Button
                style={{ marginBottom: "20px" }}
                onClick={() => navigate(`/machines/${machineId}/transactions`)}
                variant="contained"
            >
                Download transactions
            </Button><Button
                style={{ marginBottom: "20px" }}
                onClick={() => navigate(`/machines/${machineId}/channels`)}
                variant="contained"
            >
                View channels
            </Button><Button
                style={{ marginBottom: "20px" }}
                onClick={() => navigate(`/machines/${machineId}/screens`)}
                variant="contained"
            >
                Screensaver
            </Button><Button
                style={{ marginBottom: "20px" }}
                onClick={() => navigate(`/machines/${machineId}/settings`)}
                variant="contained"
            >
                Other settings
            </Button></>
            );
}

export default MachineMenu;