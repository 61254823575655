import {
  getFirestore,
  collection,
  getDocs,
  query,
  // updateDoc,
  // doc,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';



function Machines() {
  const navigate = useNavigate();
  const [Machines, setMachines] = useState({});

  function machineTransactions(machineId) {
    getDocs(
      query(
        collection(getFirestore(), "machines", machineId, "transactions"),
        orderBy("last_update", "desc")
      )
    ).then((querySnapshot) => {
      let transactions = {};
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;

        console.log(data.last_update.toDate() - Date.now())
        if (Date.now() - data.last_update.toDate() > 60 * 60 * 1000) {
          data.warning = true;
        } else if (data.air_temperature > 7) {
          data.warning = true;
        }
        else { data.warning = false; }

        let transctions = data.transactions;
        console.log(data);
        //transctions = transctions.sort((a,b)=>a.created_at<b.created_at);
        data.lastTransaction = data.transactions.find().created_at;

        Machines[doc.id] = data;
      });
      setMachines(Machines);
    });
  }

  useEffect(() => {
    // getDocs(
    //   query(
    //     collection(getFirestore(), "machines"),
    //     orderBy("location", "asc")
    //   )
    // ).then((querySnapshot) => {
    var sub = onSnapshot(query(
      collection(getFirestore(), "machines"),
      orderBy("location", "asc")
    ), (querySnapshot) => {
      let Machines = {};
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;

        console.log(data.last_update.toDate() - Date.now())





        data.warning = "";
        let error = false;
        let warning = false

        if (data.air_temperature > 7) {
          data.warning = "Temperature > 7 degrees";
          warning = true;
        }
        if (data.temperature_alarm) {
          data.warning = "Temperature alarm";
          error = true;
        }
        if (data.machine_availability != "Machine Free") {
          data.warning = data.machine_availability;
        }
        if (data.machine_door_open) {
          data.warning = "Machinen door open";
          warning = true;
        }
        if (!data.machine_enabled) {
          data.warning = "Machine disabled";
         error = true;
        }
        if (!data.machine_in_service) {
          data.warning = "Machine not in service";
          error = true;
        }
        if (Date.now() - data.last_update.toDate() > 60 * 60 * 1000) {
          data.warning = "No update from machine";
          error = true;
        }
        if (data.controller_status != "online") {
          data.warning = "Machine offline";
          error = true;
        }
        if(error) {
          data.severity = "error";
        } else if(warning){
          data.severity = "warning";
        } else {
          data.severity = "success";
        }

        console.log(data);
        //transctions = transctions.sort((a,b)=>a.created_at<b.created_at);
        //data.lastTransaction = data.transactions .created_at;

        Machines[doc.id] = data;
      });
      setMachines(Machines);
    });
  }, []);

  // const updateMachines = (id, updateData) => {
  //   console.log(updateData);
  //   updateDoc(doc(getFirestore(), "machines", id), updateData).then(() => {
  //     let newMachines = { ...Machines };
  //     newMachines[id] = {
  //       ...newMachines[id],
  //       ...updateData,
  //     };
  //     setMachines(newMachines);
  //   });
  // };

  // TODO: Add functionality to disable a machine UI

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Temperature</TableCell>
              <TableCell>Last Update</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(Machines).map((machine) => (
              <TableRow key={machine.id} onClick={() => { navigate(`${machine.id}`); }}>
                <TableCell><Alert severity={machine.severity}>{machine.warning}</Alert></TableCell>
                <TableCell>{machine.location}</TableCell>
                <TableCell>{machine.name}</TableCell>
                <TableCell>{machine.air_temperature}&deg;C</TableCell>
                <TableCell>{machine.last_update.toDate().toLocaleString()}</TableCell>
                {/* <TableCell>{machine.lastTransaction.toDate().toString()}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Outlet />
    </div>
  );
}

export default Machines;
