import {
    getFirestore,
    collection,
    getDocs,
    query,
    deleteDoc,
    doc,
    getDoc,
    orderBy,
    limit,
    onSnapshot,
    updateDoc,deleteField
  } from "firebase/firestore";
  import { useEffect, useState } from "react";
  import {
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Card,CardContent
  } from "@mui/material";
  import Alert from '@mui/material/Alert';
  import { Outlet, useParams } from "react-router-dom";
  import { useNavigate } from "react-router-dom";
  import { useLocation } from 'react-router'
  import MachineMenu from './MachineMenu'
  
  function MachineDetail() {
    let params = useParams();
    const machineId = params.machineId;
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [products, setProducts] = useState({});
    const [transactions, setTransactions] = useState({});
    const [machine, setMachine] = useState(null);
    const [productNames, setProductNames] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const [warning, setWarning] = useState("Machine status OK");
    const [machineSeverity, setMachineSeverity] = useState("success");
  
    const handleClose = () => {
      setOpen(false);
    };
  
    // function fetchData() {
    //   getDocs(
    //     query(collection(getFirestore(), `machines/${machineId}/products`),orderBy('display_order', 'asc'))
    //   ).then(async (querySnapshot) => {
    //     let products = {};
  
    //     // Map each product to a promise that resolves to the name
    //     const namePromises = querySnapshot.docs.map(async (doc) => {
    //       let data = doc.data();
    //       data.id = doc.id;
    //       products[doc.id] = data;
  
    //       if (data.reference) {
    //         const name = await getDoc(data.reference)
    //           .then((doc) => {
    //             return doc.data().name;
    //           })
    //           .catch((error) => {
    //             console.log(error);
    //             return null;
    //           });
    //         return name;
    //       }
    //     });
  
    //     // Wait for all promises to resolve
    //     const resolvedNames = await Promise.all(namePromises);
  
    //     // Update the state with the resolved names
    //     setProducts(products);
    //     setProductNames(resolvedNames);
    //   });
    // }

    function fetchMachineData() {

   
        
     var sub = onSnapshot(doc(getFirestore(), "machines", machineId), (docSnap) => {
           
                let machine = docSnap.data();
                let warning = false;
                let error = false;
                let newWarning = "Machine status OK";
                
                if (machine.air_temperature > 7) {
                  newWarning = "Temperature > 7 degrees";
                  warning = true;
                }
                if (machine.temperature_alarm ) {
                    newWarning = "Temperature alarm";
                    error = true;
                }
                if (machine.machine_availability != "Machine Free") {
                    newWarning = machine.machine_availability;
                    
                }
                if (machine.machine_door_open) {
                    newWarning = "Machinen door open";
                    warning = true;
                }
                if (!machine.machine_enabled) {
                    newWarning = "Machine disabled";
                    error = true;
                }
                if (!machine.machine_in_service) {
                    newWarning = "Machine not in service";
                    error = true;
                }
                if (Date.now() - machine.last_update.toDate() > 60 * 60 * 1000) {
                  newWarning = "No update from machine";
                  warning = true;
                }
                if(machine.controller_status != "online")
                {
                    newWarning = "Machine offline";
                    error = true;
                }
                setWarning(newWarning);
                if(error) {
                  setMachineSeverity("error");
                } else if(warning){
                  setMachineSeverity("warning");
                } else {
                  setMachineSeverity("success");
                }

        
              setMachine(docSnap.data());
            }
        );

        // onSnapshot(doc(getFirestore(), "machines", machineId)).then(
        //     (docSnap) => {
        //         let machine = docSnap.data();
        //         if (machine.temperature_alarm ) {
        //             setWarning("Temperature alarm");
        //         }
        //         if (machine.machine_availability != "Machine Free") {
        //             setWarning("Machine not available");
        //         }
        //         if (machine.machine_door_open) {
        //             setWarning("Machinen door open");
        //         }
        //         if (!machine.machine_enabled) {
        //             setWarning("Machine disabled");
        //         }
        //         if (!machine.machine_in_service) {
        //             setWarning("Machine not in service");
        //         }
        //         if(Date.now() - machine.last_update.toDate() > 60*60*1000)
        //         {
        //             setWarning("Machine offline");
        //         }
        
        //       setMachine(docSnap.data());
        //     }
        //   );



       
        
          
      }
      function getProducts(){
        getDocs(collection(getFirestore(), 'products')).then((querySnapshot) => {
          let products = {};
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            data.id = doc.id;
            products[doc.id] = data;
            
          });
          setProducts(products);
          console.log(products);
        });
      }

      function getLatestTransactions () {
       // let transactions = {};
        var sub = onSnapshot(query(collection(getFirestore(), `machines/${machineId}/transactions`), orderBy("created_at", "desc"), limit(100)), (querySnapshot) => {
        //getDocs(query(collection(getFirestore(), `machines/${machineId}/transactions`), orderBy("created_at", "desc"), limit(100))).then(
        //    (querySnapshot) => {
              
              querySnapshot.forEach((doc) => {
                let data = doc.data();
                data.id = doc.id;
                data.severity = "success";
                if(data.status == "dispensing_failed")
                {
                  data.severity = "error";
                } else if (data.status != "transaction_done")
                {
                  data.severity = "warning";
                }
                transactions[doc.id] = data;
                
              });
              setTransactions( transactions);
            }
          );
          //console.log(transactions);
          //setAllTransactions(transactions);
          //setLoading(false);
          //done(true); // Proceed and get data from dataFromListOfUsersState function
          
       
      
    }
  
    // useEffect(() => {
    //   fetchData();
    // }, [location.key])
  
    useEffect(() => {
     //fetchData();
      fetchMachineData();
      getProducts();
      getLatestTransactions();
    }, [machineId]);
  
    const onResetTemperatureAlarm = async () => {
        await updateDoc(
            doc(getFirestore(), `machines/${machineId}`),
            {temperature_alarm : false, air_temperature_alarm_start: deleteField()}
          );
          //fetchMachineData();
      };
  
    function machineinfo() { 
        
        return ( 
           <div>
            <Alert severity={machineSeverity}> {warning}</Alert>
           Location: {machine.location} <br />
           Name: {machine.name} <br />
           air_temperature: {machine.air_temperature} <br />
           {machine.temperature_alarm ? (<b>Temperature alarm active since: {machine.air_temperature_alarm_start.toDate().toLocaleString()}<br /> </b>) : ""}
           {machine.temperature_alarm ? (<Chip
                        label="Reset Alarm"
                        onClick={() => {
                          
                          setOpen(true);
                        }}
                      /> ) : ""}<br />
          Controller_status: {machine.controller_status} <br />
           Controller_version: {machine.controller_version} <br />
           last restart: {machine.last_restart.toDate().toLocaleString()} <br />
           last update: {machine.last_update.toDate().toLocaleString()} <br />
           machine_availability: {machine.machine_availability} <br />
           machine_door_open: {machine.machine_door_open ? <b>"OPEN"</b> : "closed"} <br />
           machine_enabled: {!machine.machine_enabled ? <b>"NO - ERROR"</b> : "yes"} <br />
           machine_in_service: {!machine.machine_in_service ? <b>"NO - ERROR"</b> : "yes"} <br />
           machine_status: {machine.machine_status} <br />
           </div>
        
        ); 
    }

    return (
      <div>
        {MachineMenu(machine, machineId)}
            <Card>

            <CardContent>
            {machine != null?(

machineinfo()
            ):""}
                     
                  
                
                </CardContent>
            </Card>

            <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <b>Last 100 transactions:</b>
            <TableRow>
            <TableCell>Transacttion ID</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Products</TableCell>
              <TableCell>ProductLocation</TableCell>
              <TableCell>Payter ID</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(transactions).map((transaction) => (
              <TableRow key={transaction.id}>
                <TableCell>{transaction.transactionId}</TableCell>
                <TableCell>&euro;{  transaction.price == undefined? "-.--": transaction.price.toFixed(2)}</TableCell>
                <TableCell>{transaction.products[0]?
                products[transaction.products[0].id]?.name + " "+transaction.products[0].error_status + " "+ transaction.products[0].extended_status  :""}<br />
                
                {transaction.products[1]?
                    products[transaction.products[1].id]?.name+ " "+transaction.products[1].error_status + " "+ transaction.products[1].extended_status :""}<br />{transaction.products[2]?
                        products[transaction.products[2].id]?.name+ " "+transaction.products[2].error_status + " "+ transaction.products[2].extended_status :""}</TableCell>   
                        <TableCell>{transaction.products[0]?
                transaction.products[0].tray + "-"+transaction.products[0].channel :""}<br />
                
                {transaction.products[1]?
                transaction.products[1].tray + "-"+transaction.products[1].channel :""}<br />
                {transaction.products[2]?
                transaction.products[2].tray + "-"+transaction.products[2].channel :""}<br /></TableCell>                
                
                <TableCell>{transaction.payter_sessionId}</TableCell>
                <TableCell>{transaction.created_at.toDate().toLocaleString()}</TableCell>
                <TableCell><Alert severity={transaction.severity}>{transaction.status}</Alert></TableCell>
                {/* <TableCell>{machine.lastTransaction.toDate().toString()}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
            

        <Outlet />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Reset temperature alarm"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              If products are ok, and sales can be resumed, you should reset the temperature alarm.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() => {
                onResetTemperatureAlarm();
                handleClose();
              }}
              autoFocus
            >
              Reset temperature alarm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  
  export default MachineDetail;
  